import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  login: Yup.string()
    /* .min(6, 'Минимальная длина - 6 символов')
    .max(12, 'Максимальная длина - 12 символов') */
    .required('Обязательное поле'),
  password: Yup.string()
    /* .min(6, 'Минимальная длина - 6 символов')
    .max(20, 'Максимальная длина - 20 символов')
    .matches(/^(?=.*\d).*$/, 'Хотя бы 1 цифра')
    .matches(/^(?=.*[!@#$%^&*]).*$/, 'Хотя бы 1 специальный символ')
    .matches(/^(?=.*[a-z]).*$/, 'Хотя бы 1 символ в нижнем регистре')
    .matches(/^(?=.*[A-Z]).*$/, 'Хотя бы 1 символ в верхнем регистре')
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      'Только латинница'
    ) */
    .required('Обязательное поле'),
});
