import { Box } from '@material-ui/core';
import React from 'react';

export const BigLogo: React.FC = () => {
  return (
    <>
      <Box maxWidth={200} clone mt={-4} mb={2}>
        <img src={'/images/newLogo.png'} />
      </Box>
      {/* <Box clone fontSize={44}>
        <div>ПОЛТАВА КРЫМ</div>
      </Box> */}
    </>
  );
};
