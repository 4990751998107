import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const TableLoader: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress style={{ color: 'black' }} />
    </div>
  );
};
