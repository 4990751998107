import React, { useEffect, useState } from 'react';
import { Paper, Button } from '@material-ui/core';
import DebounceInput from './DebounceInput';
import SelectDetailsScreen from './SelectDetailsScreen';
import SelectMediaBanner from './SelectMediaBanner';
import { MediaType } from 'types/global';
import { deleteInfoBanner, postEditInfoBanner } from 'api/banner';
import { toast } from 'react-toastify';

const CreateInfoBannerCard = ({ selectedRowInfo, refresh }: any) => {
  const [data, setData] = useState(selectedRowInfo);
  const [loading, setLoading] = useState(false);

  const [_, update] = useState({});

  useEffect(() => {
    setTimeout(() => {
      console.log('updated');
      update({});
    }, 1000);
  }, []);

  console.log('update', refresh);

  useEffect(() => {
    setData(selectedRowInfo);
  }, [selectedRowInfo]);

  const handleTextChange = (field: keyof typeof data) => (newValue: string) => {
    setData((prev: any) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: number;
    }>
  ) => {
    setData((prev: any) => ({
      ...prev,
      detailsScreenId: event.target.value,
    }));
  };

  const handleFileChange = (media: MediaType) => {
    setData((prev: any) => ({
      ...prev,
      media,
    }));
  };

  const handleDelete = async () => {
    setLoading(true);
    const res = await deleteInfoBanner(data.id);
    if (res?.data?.data) {
      toast.success('Удалено');
    } else {
      toast.error('Ошибка');
    }
    refresh();
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const res: any = await postEditInfoBanner(data);
    if (res?.data?.data) {
      toast.success('Сохранено');
    } else {
      toast.error('Ошибка');
    }
    refresh();
    setLoading(false);
  };

  const handleToggle = async () => {
    setLoading(true);
    const res = await postEditInfoBanner({ ...data, isActive: !data.isActive });
    if (res?.data?.data) {
      toast.success('Сохранено');
    } else {
      toast.error('Ошибка');
    }
    refresh();
    setLoading(false);
  };

  return (
    <Paper
      style={{
        minHeight: 156,
        padding: 8,
        position: 'relative',
      }}
    >
      <SelectMediaBanner setValue={handleFileChange} value={data?.media} />
      <DebounceInput
        outerValue={data?.title || ''}
        setValue={handleTextChange('title')}
        label={'Заголовок'}
        placeholder="Заголовок"
      />
      <DebounceInput
        outerValue={data?.subTitle || ''}
        setValue={handleTextChange('subTitle')}
        label={'Подзаголовок'}
        placeholder="Подзаголовок"
      />
      <SelectDetailsScreen
        value={data?.detailsScreenId || null}
        setValue={handleSelectChange}
      />
      <Button
        style={{ marginTop: 16, marginBottom: 16 }}
        fullWidth
        color="primary"
        disabled={loading}
        onClick={handleSave}
        variant="contained"
      >
        {loading ? 'Ожидайте...' : 'Сохранить'}
      </Button>
      {data?.id !== -1 && (
        <Button
          onClick={handleDelete}
          style={{ marginBottom: 16 }}
          fullWidth
          disabled={loading}
          variant="contained"
        >
          {loading ? 'Ожидайте...' : 'Удалить'}
        </Button>
      )}
      {data?.id !== -1 && (
        <Button
          onClick={handleToggle}
          color="secondary"
          disabled={loading}
          style={{ marginBottom: 16 }}
          fullWidth
          variant="contained"
        >
          {data?.isActive ? 'Отключить' : 'Включить'}
        </Button>
      )}
    </Paper>
  );
};

export default CreateInfoBannerCard;
