/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BlocksData } from 'interface/wisiwyg';
import { instance } from './instance';

export const postEditBanner = ({ id, ...otherParams }: BlocksData) =>
  instance.post(`/terminal/admin/banner/${id}/update`, otherParams);

export const getCurrentBanner = ({ id }: { id: number }) =>
  instance.get(`/terminal/admin/banner/${id}`);

export const postEditInfoBanner = (body: any) =>
  instance.post('/info/admin/banners/upsert', body);

export const deleteInfoBanner = (id: number) =>
  instance.delete(`/info/admin/banners/${id}`);
