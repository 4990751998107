import React, { useState } from 'react';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { useStyles } from './styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Clock } from 'common/Clock/Clock';
import { Button, Container, Fade } from '@material-ui/core';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { useEffect } from 'react';

interface Props {
  editMode: boolean;
  time: boolean;
  backButton: string;
  image: string;
  handleImageChange: (path: string) => void;
}

export const SectionBg: React.FC<Props> = ({
  editMode = false,
  time = true,
  backButton = '',
  image = '',
  handleImageChange,
}: Props) => {
  const classes = useStyles({ editMode });

  const [currentStyle, setCurrentStyle] = useState<Record<string, string>>({
    backgroundSize: image ? 'cover' : 'unset',
    backgroundRepeat: 'no-repeat',
  });
  const [hover, setHover] = useState<boolean>(false);
  const [selectImageDialogOpen, setSelectImageDialogOpen] =
    useState<boolean>(false);

  const handleBack = () => {
    console.log('handleBack');
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    setCurrentStyle({
      ...currentStyle,
      backgroundImage: 'url(' + process.env.REACT_APP_SERVER_URL + image + ')',
      backgroundSize: image ? 'cover' : 'unset',
    });
  }, [image]);

  return (
    <div className={classes.root}>
      <Container
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={currentStyle}
      >
        <>
          <div className={classes.header}>
            {!!backButton && (
              <BrandButton
                onClick={handleBack}
                size="small"
                color="primary"
                style={{ background: 'white' }}
                startIcon={<ArrowBackIosRoundedIcon fontSize="small" />}
              >
                Назад
              </BrandButton>
            )}
            {time && (
              <div className={classes.clock}>
                <Clock />
              </div>
            )}
          </div>
          {editMode && (
            <>
              <Fade in={hover} timeout={150}>
                <label
                  htmlFor="mainBgInput"
                  className={classes.editButtonWrapper}
                >
                  <Button
                    component="span"
                    style={{ textTransform: 'none' }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setSelectImageDialogOpen(true)}
                  >
                    {image ? 'Изменить изображение' : 'Загрузить изображение'}
                  </Button>
                  <SelectImageDialog
                    endPoint={'/terminal/admin/banners/image/main'}
                    open={selectImageDialogOpen}
                    setOpen={setSelectImageDialogOpen}
                    onFileChange={handleImageChange as any}
                  />
                </label>
              </Fade>
              <Fade in={hover} timeout={150}>
                <div className={classes.backdrop} />
              </Fade>
            </>
          )}
        </>
      </Container>
    </div>
  );
};
