import React from 'react';
import { Box, Container, Fade, Grid } from '@material-ui/core';
import { LoginForm } from './LoginForm';
import { BigLogo } from './BigLogo';

export const Login: React.FC = () => {
  return (
    <Fade in timeout={300}>
      <Container>
        <Box height="100vh" clone>
          {/* TODO: Сделать логотип на входе */}
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <BigLogo />
            <LoginForm />
          </Grid>
        </Box>
      </Container>
    </Fade>
  );
};
