import {
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { DRAWER_LINKS } from '_constants';
import { useStyles } from './styles';

export const SideMenu: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Hidden mdDown>
      <Paper
        style={{
          marginLeft: 16,
          marginTop: 24,
        }}
      >
        {DRAWER_LINKS.map((item) => {
          if (pathname.includes(item.link)) {
            return (
              <List
                key={item.link}
                style={{
                  minWidth: 271,
                }}
                component="nav"
                aria-labelledby="list-subheader"
              >
                {item.menu.map((menuItem) => (
                  <ListItem
                    className={`${classes.listItem} ${
                      pathname.includes(menuItem.link)
                        ? classes.activeListItem
                        : 'i'
                    }`}
                    button
                    onClick={() => history.push(menuItem.link)}
                    key={menuItem.title}
                  >
                    <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                    <ListItemText primary={menuItem.title} />
                  </ListItem>
                ))}
              </List>
            );
          }
        })}
      </Paper>
    </Hidden>
  );
};
