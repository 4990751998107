import React from 'react';
import { AddButton, AddButtonProps } from './AddButton';
import { File, FileProps } from './File';

interface IProps {
  children: React.ReactNode;
  title: string;
}

type FilesListType = {
  ({ title, children }: IProps): JSX.Element;
  File: React.FC<FileProps>;
  AddButton: React.FC<AddButtonProps>;
};

const FilesList: FilesListType = ({ title, children }: IProps) => {
  return (
    <div>
      <input
        type="file"
        style={{ height: 0, width: 0, opacity: 0 }}
        id={`${title}_input`}
      />
      <h3>{title}</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
};

FilesList.File = File;
FilesList.AddButton = AddButton;

export { FilesList };
