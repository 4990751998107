import { IconButton, useTheme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useWisiwygBoxStyles } from './styles';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

interface Props {
  children: React.ReactNode;
  handleDeleteBlock?: () => void;
  editMode: boolean;
  handleMoveUp?: () => void;
  handleMoveDown?: () => void;
  name?: string;
}

export const EditWisiwygBox: React.FC<Props> = ({
  children,
  handleDeleteBlock,
  handleMoveUp,
  handleMoveDown,
  name,
  editMode = false,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  const classes = useWisiwygBoxStyles({ hover });
  const theme = useTheme();

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return editMode ? (
    <div
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Toolbar>
        <Typography style={{ margin: '0 auto 0 8px' }}>
          {!!name && name}
        </Typography>
        <IconButton size="small" onClick={handleDeleteBlock}>
          <DeleteForeverOutlinedIcon
            fontSize="small"
            htmlColor={theme.palette.error.main}
          />
        </IconButton>
        {!!handleMoveUp && (
          <IconButton size="small" onClick={handleMoveUp}>
            <ArrowDownwardRoundedIcon fontSize="small" />
          </IconButton>
        )}
        {!!handleMoveDown && (
          <IconButton size="small" onClick={handleMoveDown}>
            <ArrowDownwardRoundedIcon
              style={{ transform: 'rotate(180deg)' }}
              fontSize="small"
            />
          </IconButton>
        )}
      </Toolbar>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

const Toolbar = ({ children }: { children: React.ReactNode }) => {
  const classes = useWisiwygBoxStyles();
  return (
    <div className={classes.toolbar} id="toolbar">
      {children}
    </div>
  );
};
