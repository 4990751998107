import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import React, { useState, FC } from 'react';
import { MediaType } from 'types/global';
import Image from 'ui/Image/Image';

type Props = {
  value: MediaType;
  setValue: (media: MediaType) => void;
};

const SelectMediaBanner: FC<Props> = ({ value, setValue }) => {
  const [open, setOpen] = useState(false);

  const handleSelectFile = (media: MediaType) => {
    setOpen(false);
    setValue(media);
  };

  console.log('value', value);

  return (
    <>
      <Image onClick={() => setOpen(true)} src={value?.downloadUrl} />

      {open && (
        <SelectImageDialog
          open={open}
          setOpen={setOpen}
          onFileChange={handleSelectFile}
        />
      )}
    </>
  );
};

export default SelectMediaBanner;
