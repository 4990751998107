import { makeStyles } from '@material-ui/core';

export const useBlockEditStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 32,
    width: 500,

    '& > *': {
      marginBottom: 32,
    },
  },
});
