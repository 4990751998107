import { makeStyles } from '@material-ui/core';

export const useLabeledStyles = makeStyles({
  root: {
    position: 'relative',
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    color: 'black',
    postiion: 'absolute',
    left: 0,
    top: '-10px',
  },
});
