export enum LoginActions {
  LOGIN = 'LOGIN',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  ME_SUCCESS = 'ME_SUCCESS',
  ME_FAILED = 'ME_FAILED',
  ME = 'ME',
  LOGOUT = 'LOGOUT',
  START_LOADING = 'START_LOADING',
  END_LOADING = 'END_LOADING',
}

export enum AuthState {
  WAIT_AUTH = -2,
  NOT_AUTH = -1,
  HAS_AUTH = 1,
}

export type ISchedule = {
  time: string;
  name: string;
  guest: string;
  guestColor: string;
  room: string;
  icon: string;
  tintColor: string;
  backgroundGradient: {
    startColor: string;
    endColor: string;
  };
};

export interface IWorkPlace {
  name: string;
  schedules: ISchedule[];
}

export interface IDay {
  day: string;
  workPlaces: IWorkPlace[];
}
export interface SchedilesInfo {
  employeeUuid?: string;
  days?: IDay[] | [];
}

export interface Employee {
  id: number;
  id1c: number;
  name: string;
  surname: string;
  patronymic: string;
  login: string;
  password: string;
}

export interface EmployeeState extends SchedilesInfo, Employee {
  isAuth: AuthState;
  isLoading: boolean;
}

export interface IAction {
  type: LoginActions;
  payload: Record<string, unknown>;
}

export type LoginParams = {
  login: string;
  password: string;
};

export type TryLoginAction = {
  type: LoginActions.LOGIN;
  payload: LoginParams;
};

export type LogoutAction = {
  type: LoginActions.LOGOUT;
};

export type MeSuccess = {
  type: LoginActions.ME_SUCCESS;
  payload: Employee;
};

export type MeFailed = {
  type: LoginActions.ME_FAILED;
};

export type LoginSuccess = {
  type: LoginActions.LOGIN_SUCCESS;
};

export type LoginFailed = {
  type: LoginActions.LOGIN_FAILED;
};

export type LoadingStart = {
  type: LoginActions.START_LOADING;
};

export type LoadingEnd = {
  type: LoginActions.END_LOADING;
};

export type AuthActions =
  | TryLoginAction
  | LogoutAction
  | MeSuccess
  | MeFailed
  | LoginSuccess
  | LoginFailed
  | LoadingStart
  | LoadingEnd;
