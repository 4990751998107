import { IOption } from './global';

export interface ReservingData {
  physician: IOption | null;
  patient: IOption | null;
  procedure: IOption | null;
  room: IOption | null;
  dateFrom: string | Date;
  dateTo: string | Date;
  comment: string;
}

export enum ReservingActions {
  SET_DATE_FROM = 'SET_DATE_FROM',
  SET_DATE_TO = 'SET_DATE_TO',
  SET_PHYS = 'SET_PHYS',
  SET_PAT = 'SET_PAT',
  SET_PROCEDURE = 'SET_PROCEDURE',
  SET_COMMENT = 'SET_COMMENT',
  SET_ROOM = 'SET_ROOM',
}

export type CommentAction = {
  type: ReservingActions.SET_COMMENT;
  payload: string;
};

export type SelectAction = {
  type:
    | ReservingActions.SET_PHYS
    | ReservingActions.SET_PAT
    | ReservingActions.SET_PROCEDURE
    | ReservingActions.SET_ROOM;
  payload: IOption | null;
};

export type DateAction = {
  type: ReservingActions.SET_DATE_FROM | ReservingActions.SET_DATE_TO;
  payload: Date | string;
};

export type ReservingAction = CommentAction | SelectAction | DateAction;
