import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ReservingAction } from 'interface/reserving';

type TCallback =
  | ((value: string) => (dispatch: Dispatch<ReservingAction>) => void)
  | undefined;

type THook = [string, (event: React.ChangeEvent<HTMLInputElement>) => void];

export const useHandleChange = (
  callback: TCallback,
  initialValue: string | undefined
): THook => {
  const dispatch = useDispatch();

  const [localValue, setLocalValue] = useState(initialValue ?? '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    if (initialValue !== undefined) {
      setLocalValue(newValue);
    }
    if (callback) {
      dispatch(callback(newValue));
    }
  };

  return [localValue, handleChange];
};
