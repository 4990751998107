import { DetailType } from 'enums/global';
import React from 'react';
import Title from './Blocks/Title';
import Subtitle from './Blocks/Subtitle';
import Paragraph from './Blocks/Paragraph';
import List from './Blocks/List';
import Link from './Blocks/Link';
import Img from './Blocks/Img';
import Icons from './Blocks/Icons';
import Header from './Blocks/Header';
import Gallery from './Blocks/Gallery';
import { EditWisiwygBox } from 'HOC/EditWisiwygBox/EditWisiwygBox';

const DetailBlock = ({
  moveTop,
  moveBottom,
  delete: deleteFn,
  setData,
  ...props
}: any) => {
  const makeName = () => {
    switch (props.type) {
      case DetailType.title:
        return 'Заголовок';

      case DetailType.subtitle:
        return 'Подзаголовок';

      case DetailType.paragraph:
        return 'Текст';

      case DetailType.list:
        return 'Список';

      case DetailType.link:
        return 'Ссылка';

      case DetailType.image:
        return 'Изображение';

      case DetailType.icons:
        return 'Иконки';

      case DetailType.header:
        return 'Описание комнаты';

      case DetailType.gallery:
        return 'Галерея';
      default:
        return 'Блок';
    }
  };
  const makeComponent = () => {
    switch (props.type) {
      case DetailType.title:
        return <Title setData={setData} {...props} />;

      case DetailType.subtitle:
        return <Subtitle setData={setData} {...props} />;

      case DetailType.paragraph:
        return <Paragraph setData={setData} {...props} />;

      case DetailType.list:
        return <List setData={setData} {...props} />;

      case DetailType.link:
        return <Link setData={setData} {...props} />;

      case DetailType.image:
        return <Img setData={setData} {...props} />;

      case DetailType.icons:
        return <Icons setData={setData} {...props} />;

      case DetailType.header:
        return <Header setData={setData} {...props} />;

      case DetailType.gallery:
        return <Gallery setData={setData} {...props} />;
      default:
        return <>{props.type}</>;
    }
  };
  return (
    <EditWisiwygBox
      editMode
      name={makeName()}
      handleMoveUp={moveTop}
      handleMoveDown={moveBottom}
      handleDeleteBlock={deleteFn}
    >
      {makeComponent()}
    </EditWisiwygBox>
  );
};

export default DetailBlock;
