import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import React, { useState } from 'react';
import { IconItem } from './IconItem';
import { useIconListStyles } from './styles';
import { useIconWithPicker } from './useIconWithPicker';
import { MediaType } from 'types/global';
import { AddButton } from 'compounds/FilesList/AddButton';
import DebouncedSearch from './DebouncedSearch';
import { Pagination } from 'common/Pagination/Pagination';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFileChange: (icon: MediaType) => void;
  endPoint?: string;
}

export const SelectImageDialog: React.FC<Props> = ({
  open,
  setOpen,
  onFileChange,
  endPoint = '/uploads/images',
}: Props) => {
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useIconListStyles();
  const [page, setPage] = useState(1);

  const {
    isIconsFetching,
    iconList,
    handleUploadInputChange,
    handleDeleteIcon,
    limit,
    handlePageChange,
    handleSearchChange,
  } = useIconWithPicker({ onFileChange, endPoint });

  return (
    <Dialog
      onBackdropClick={handleClose}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
    >
      <DialogContent style={{ padding: 32 }}>
        <DebouncedSearch outerValue="" setValue={handleSearchChange} />
        {isIconsFetching ? (
          <Box width="100%" height=" 100%" textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <div>
            <div className={classes.iconList}>
              <label>
                <AddButton noLabel size={80} />
                <input
                  hidden
                  type="file"
                  accept="image/png"
                  className="none"
                  onChange={handleUploadInputChange}
                />
              </label>
              {iconList.map((icon) => (
                <IconItem
                  icon={icon}
                  key={icon.uuid}
                  onClick={onFileChange}
                  onDelete={handleDeleteIcon}
                />
              ))}
            </div>
          </div>
        )}
        <Pagination
          onPageChange={(page: number) => {
            handlePageChange(page);
            setPage(page);
          }}
          totalCount={iconList.length === limit ? page + 1 : page}
          page={page}
        />
      </DialogContent>
    </Dialog>
  );
};
