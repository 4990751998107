import { InputBase, Typography } from '@material-ui/core';
import { useDebounce } from 'hooks/useDebounce';
import { BlockComponent, BLOCK_TYPE, IBlock } from 'interface/wisiwyg';
import React from 'react';
import { useStyles } from '../styles';

export const ParagraphBlock: React.FC<
  BlockComponent & IBlock<BLOCK_TYPE.PARAGRAPH>
> = ({
  value: outerValue,
  editMode,
  index,
  handleEditBody,
}: BlockComponent & IBlock<BLOCK_TYPE.PARAGRAPH>) => {
  const [value, handleChange] = useDebounce(
    outerValue,
    (newValue: string) => handleEditBody(newValue, index),
    400,
    true
  );
  const classes = useStyles();

  return editMode ? (
    <InputBase
      placeholder="Введите текст..."
      value={value}
      className={classes.paragraphBlockBody}
      onChange={handleChange}
      fullWidth
      multiline
    />
  ) : (
    <Typography>{outerValue}</Typography>
  );
};
