import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 350;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      borderLeft: '3px solid transparent',
      transition: '.3s',
    },
    listItem: {
      borderLeft: '3px solid transparent',
      color: theme.palette.grey[400],
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: theme.spacing(9),
      width: '100%',
    },
    activeListItem: {
      color: theme.palette.primary.main,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      background: 'rgba(39, 140, 245, .1)',
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    navLink: {
      height: '100%',
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      fontWeight: 400,
      borderBottom: '3px solid transparent',
      textTransform: 'none',
      textDecoration: 'none',
    },
    activeNavLink: {
      fontWeight: 500,
      borderCollor: theme.palette.primary.main,
    },
    navLinkContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      marginRight: 32,
    },
  })
);
