import React from 'react';
import { Fade, Typography, TableRow, TableCell } from '@material-ui/core';
import { DataTable } from 'common/DataTable/DataTable';
import { ITableBodyRenderer } from 'interface/global';
import { ColorItem } from 'types/global';
import CircleColorPicker from 'common/ColorPicker/CircleColorPicker';
import { doRequest } from 'hooks/doRequest';
import { postEditColors } from 'api/colors';

const ColorsPage: React.FC = () => {
  const handleBodyRenderer: ITableBodyRenderer<ColorItem[]> = (
    data,
    setInfo,
    refetch
  ) =>
    data?.map((row: ColorItem) => {
      const handleColorChange = async (color: string) => {
        const colorsCopy = data.map((i) => {
          if (i.name === row.name) {
            return {
              name: row.name,
              color,
            };
          }
          return i;
        });
        await doRequest(postEditColors, colorsCopy);
        refetch && refetch();
      };

      return (
        <TableRow style={{ cursor: 'pointer' }} key={row.name}>
          <TableCell scope="row">{row.name}</TableCell>
          <TableCell align="left">
            <CircleColorPicker
              initialValue={row.color}
              onChange={handleColorChange}
            />
          </TableCell>
        </TableRow>
      );
    });
  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Цвета</Typography>

        <DataTable
          header={['Имя', 'Цвет']}
          bodyRenderer={handleBodyRenderer}
          endPoint={'/schedule/admin/colors'}
        />
      </div>
    </Fade>
  );
};

export default ColorsPage;
