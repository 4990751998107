import { makeMain } from 'api/sections';
import EmptyBlock from 'components/EmptyBlock/EmptyBlock';
import { doRequest } from 'hooks/doRequest';
import { useStyledBlocksList } from 'pages/Blocks/components/styles';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { InfoBlockType } from 'types/global';

export type BlockListsType = {
  data: any[];
  handleCreateClick: () => void;
  handleClick: (id: number) => () => void;
  disabled?: boolean;
  disabledReason?: string;
  onSave?: (blockData: InfoBlockType) => any;
  withMain?: boolean;
  mainIds?: InfoBlockType[];
  getData?: (args?: any) => void;
};

const BlockLIsts: FC<BlockListsType> = ({
  data,
  handleCreateClick,
  handleClick,
  disabled,
  disabledReason,
  withMain,
  getData,
  mainIds,
}) => {
  const classes = useStyledBlocksList();
  const { sectionId } = useParams<{ sectionId: string }>();

  const handleDeleteSub = (id: number) => async () => {
    await doRequest(makeMain, {
      sectionId: Number(sectionId),
      ids: mainIds?.filter((main) => main.id !== id).map((i) => i.id),
    });
    getData && getData();
  };

  const handleMakeSub = (id: number) => async () => {
    const mainIdsCopy = [...(mainIds as any[])];
    await doRequest(makeMain, {
      sectionId: Number(sectionId),
      ids: [...mainIdsCopy, data.find((d) => d.id === id)].map((i) => i.id),
    });
    getData && getData();
  };

  return (
    <div>
      {withMain && mainIds && (
        <>
          <div className={classes.root}>
            <EmptyBlock
              disabled={disabled}
              disabledReason={disabledReason}
              onClick={handleCreateClick}
            />
            {mainIds.map((item: InfoBlockType) => (
              <EmptyBlock
                describe={item.name || item.title}
                imgSrc={item.media?.downloadUrl}
                key={item.id}
                isMain
                onSidebuttonClick={handleDeleteSub(item.id)}
                onClick={handleClick(item.id)}
              />
            ))}
          </div>
          <div
            style={{
              marginTop: 16,
              marginBottom: 16,
              background: '#F3F3F3',
              width: '100%',
              padding: '0px 64px',
              height: 2,
            }}
          />
        </>
      )}
      <div className={classes.root}>
        <EmptyBlock
          disabled={disabled}
          disabledReason={disabledReason}
          onClick={handleCreateClick}
        />
        {data.map((item: InfoBlockType) => {
          if (mainIds && mainIds.find((i) => i.id === item.id)) return null;
          return (
            <EmptyBlock
              describe={item.name || item.title}
              imgSrc={item.media?.downloadUrl}
              key={item.id}
              onClick={handleClick(item.id)}
              onSidebuttonClick={handleMakeSub(item.id)}
            />
          );
        })}
      </div>
      o
    </div>
  );
};

export default BlockLIsts;
