import { makeStyles } from '@material-ui/core';

export const useWisiwygBoxStyles = makeStyles(() => ({
  root: {
    border: (props?: { hover?: boolean }) =>
      props?.hover ? '1px solid rgba(0,0,0,.1)' : '1px solid transparent',
    cursor: 'pointer',
    transition: '.3s',
    borderRadius: 12,
    position: 'relative',
    borderTopRightRadius: 0,
    padding: 8,
    '&:hover > #toolbar': {
      display: 'flex',
    },
  },
  toolbar: {
    position: 'absolute',
    background: '#F5F5F5',
    borderTop: '1px solid rgba(0,0,0,.1)',
    borderLeft: '1px solid rgba(0,0,0,.1)',
    borderRight: '1px solid rgba(0,0,0,.1)',
    boxShadow: '0px -16px 22px -6px rgba(34, 60, 80, 0.28)',
    right: -1,
    top: 0,
    transform: 'translate(0,-100%)',
    minWidth: 300,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: '.3s ease',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));
