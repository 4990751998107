import { useMenuHandle } from 'hooks/useMenuHandle';
import React, { useEffect, useState, FC } from 'react';
import { Menu } from '@material-ui/core';
import { ColorPicker } from 'common/ColorPicker';

interface CircleColorPickerProps {
  label?: string;
  onChange?: (color: string) => void;
  value?: string;
  initialValue?: string;
}

const CircleColorPicker: FC<CircleColorPickerProps> = ({
  label,
  onChange,
  value,
  initialValue,
}) => {
  const [localValue, setLocalValue] = useState<string>(
    initialValue || value || ''
  );

  const { handleOpen, windowProps } = useMenuHandle();

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  useEffect(() => {
    setLocalValue(initialValue ?? '');
  }, [initialValue]);

  const handleChange = (color: string) => {
    setLocalValue(color);
    onChange && onChange(color);
  };

  return (
    <div>
      {!!label && <div>{label}:</div>}
      <div
        onClick={handleOpen}
        style={{
          borderRadius: '100%',
          width: 48,
          height: 48,
          cursor: 'pointer',
          backgroundColor: localValue,
          border: '1px solid rgba(0,0,0,.2)',
        }}
      />

      <Menu {...windowProps}>
        <ColorPicker
          initialColor={localValue}
          handleColorChange={handleChange}
        />
      </Menu>
    </div>
  );
};

export default CircleColorPicker;
