import React, { useState, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import { useCommonDebounce } from 'hooks/useCommonDebounce';
import { DetailType } from 'enums/global';

const Title = (props: any) => {
  const [data, setData] = useState(props.text);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setData(e.target.value);

  const debouncedValue = useCommonDebounce(data, 400);

  useEffect(() => {
    props.setData({
      text: debouncedValue,
      id: props.id,
      infoDetailsScreenId: props.infoDetailsScreenId,
      type: DetailType.title,
    });
  }, [debouncedValue]);

  return (
    <InputBase
      placeholder="Заголовок"
      fullWidth
      value={data}
      onChange={handleChange}
      multiline
      style={{
        fontWeight: 700,
        fontSize: 20,
        lineHeight: '24px',
        color: '#262626',
        marginBottom: 20,
      }}
    />
  );
};

export default Title;
