import React, { useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { RoomHeaderType } from 'types/global';
import { InputBase, Select, FormControl, InputLabel } from '@material-ui/core';
import { useBlocksStyles } from './ui/styles';
import { useDebounceFunction } from 'hooks/useDebounceFunction';

const Header = ({ setData: extSetData, ...props }: any) => {
  const classes = useBlocksStyles();

  const [data, setData] = useState<Partial<RoomHeaderType>>(props);

  const debouncedSetData = useDebounceFunction(extSetData, 300);

  const handleChangeText =
    (field: 'text' | 'price' | 'stars' | 'places') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
      console.log('props', props, data);
      debouncedSetData({
        ...data,
        [field]: e.target.value,
      });
    };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <InputBase
          className={classes.headerInput}
          placeholder={'Название'}
          value={data.text}
          multiline
          onChange={handleChangeText('text')}
        />
        <InputBase
          className={classes.priceInput}
          placeholder="цена"
          style={{ width: 215 }}
          value={data.price}
          onChange={handleChangeText('price')}
        />
      </div>
      <div
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Rating
          value={data.stars}
          onChange={(event, newValue) => {
            setData((prev) => ({
              ...prev,
              stars: newValue as number,
            }));
            extSetData({
              ...data,
              stars: newValue,
            });
          }}
        />

        <FormControl>
          <InputLabel htmlFor="age-native-simple">Кол-во</InputLabel>
          <Select
            native
            value={data.places}
            onChange={handleChangeText('places') as any}
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            <option value={1}>1 местный</option>
            <option value={2}>2х местный</option>
            <option value={3}>3х местный</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Header;
