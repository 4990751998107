import React, { FC } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

type BrandSelectProps = {
  selectId: string;
  value: string | number;
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
    child: React.ReactNode
  ) => void;
  options: { id: number | string; title: string }[];
  label?: string;
};

const BrandSelect: FC<BrandSelectProps> = ({
  selectId,
  value,
  onChange,
  options,
  label,
}) => {
  return (
    <FormControl
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <InputLabel id={selectId}>{label}</InputLabel>
      <Select fullWidth labelId={selectId} value={value} onChange={onChange}>
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BrandSelect;
