import React, { useState } from 'react';
import { Fade, Grid } from '@material-ui/core';
import { QueryFilter } from './QueryFilter';
import { List } from './List';
import { ITableBodyRenderer } from 'interface/global';

interface Props {
  queryFilter?: React.ReactNode;
  header: string[];
  bodyRenderer: ITableBodyRenderer;
  renderSideCard?: (
    selectedRowInfo: number | null
  ) => Element | JSX.Element | null;
  endPoint: string;
}

const DataTable = ({
  queryFilter = <QueryFilter />,
  header,
  bodyRenderer,
  renderSideCard,
  endPoint,
}: Props): JSX.Element => {
  const [selectedRowInfo, setSelectedRowInfo] = useState<number | null>(null);
  return (
    <>
      {queryFilter}

      <Grid container justify="space-between" spacing={2}>
        <Grid item md={renderSideCard ? 7 : 12} lg={renderSideCard ? 7 : 12}>
          <List
            setSelectedRowInfo={setSelectedRowInfo}
            header={header}
            endPoint={endPoint}
            bodyRenderer={bodyRenderer}
          />
        </Grid>
        {!!renderSideCard && (
          <Grid item md={5} lg={5}>
            <Fade in={selectedRowInfo !== null} timeout={500}>
              <div style={{ position: 'sticky', top: 89 }}>
                {renderSideCard(selectedRowInfo)}
              </div>
            </Fade>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { DataTable };
