import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { reservingReducer } from './reservingReducer';

export const rootReducer = combineReducers({
  user: loginReducer,
  reserving: reservingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
