import React, { FC, useState, useEffect } from 'react';
import { TextFieldProps, TextField } from '@material-ui/core';
import { useDebounceFunction } from 'hooks/useDebounceFunction';

type DebouncedSearchProps = {
  setValue: (newValue: string) => void;
  outerValue: string;
} & TextFieldProps;

const DebouncedSearch: FC<DebouncedSearchProps> = ({
  setValue,
  outerValue,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(outerValue);

  useEffect(() => {
    setLocalValue(outerValue);
  }, [outerValue]);

  const setOuterState = useDebounceFunction(setValue, 300);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    setOuterState(e.target.value);
  };

  return (
    <TextField
      fullWidth
      multiline
      label={'Поиск'}
      value={localValue}
      onChange={handleChange}
      style={{ marginTop: 8, marginBottom: 8 }}
      {...props}
    />
  );
};

export default DebouncedSearch;
