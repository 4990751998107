import React, { useState } from 'react';
import { IconItemType, IconsType, MediaType } from 'types/global';
import Image from 'ui/Image/Image';
import { Box, InputBase } from '@material-ui/core';
import { AddButton } from 'compounds/FilesList/AddButton';
import DeleteLabel from 'ui/DeleteLabel/DeleteLabel';
import NoSimIcon from '@material-ui/icons/NoSim';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { useBlocksStyles } from './ui/styles';
import { useDebounceFunction } from 'hooks/useDebounceFunction';
import { nanoid } from 'nanoid';

const emptyIcon = (id: any) => ({
  icon: undefined,
  id,
  description: '',
});

const Icons = ({ setData: extSetData, ...props }: any & IconsType) => {
  const classes = useBlocksStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Partial<IconsType>>(props);

  const debouncedSetData = useDebounceFunction(extSetData, 400);

  const handleDeleteIcon = (ind: number) => () => {
    const newState = {
      ...data,
      iconsItems: data?.iconsItems?.filter((_, index) => index !== ind) || [],
    };
    setData(newState);
    debouncedSetData(newState);
  };

  const handleCreateItem = () => {
    const newState = {
      ...data,
      iconsItems: [...(data?.iconsItems || []), emptyIcon(nanoid())],
    };
    setData(newState);
    debouncedSetData(newState);
  };

  const handleDescriptionChange =
    (ind: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const copy = { ...data };
      (copy.iconsItems as IconItemType[])[ind].description = e.target.value;

      setData(copy);
      debouncedSetData(copy);
    };

  const handleSetFile = (ind: number) => (icon: MediaType) => {
    const copy = { ...data };
    (copy.iconsItems as IconItemType[])[ind].icon = icon;

    setData(copy);
    setOpen(false);
    debouncedSetData(copy);
  };

  return (
    <Box className={`${classes.centerInput} ${classes.row}`}>
      {data?.iconsItems?.map((item, ind) => (
        <Box
          style={{
            width: 80,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 8,
          }}
          key={item.id}
        >
          <SelectImageDialog
            open={open}
            endPoint={'/uploads/icons'}
            setOpen={setOpen}
            onFileChange={handleSetFile(ind)}
          />
          <Box onClick={() => setOpen(true)}>
            {item.icon ? (
              <Image
                style={{ width: 56, height: 56 }}
                src={item?.icon?.downloadUrl}
                alt={item?.icon?.name}
                hover
              />
            ) : (
              <NoSimIcon style={{ width: 56, height: 56 }} />
            )}
          </Box>
          <InputBase
            multiline
            value={item.description}
            onChange={handleDescriptionChange(ind)}
            placeholder="Описание"
            style={{ textAlign: 'center', fontSize: 12 }}
          />
          <DeleteLabel onClick={handleDeleteIcon(ind)} />
        </Box>
      ))}
      <AddButton size={75} onClick={handleCreateItem} />
    </Box>
  );
};

export default Icons;
