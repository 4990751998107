import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useHandleSelect } from 'hooks/useHandleSelect';
import {
  setComment,
  setPatient,
  setPhysician,
  setProcedure,
  setRoom,
} from '../../redux/action-creators/reserving';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDebounce } from '../../hooks/useDebounce';

export const ReservingFields: React.FC = () => {
  const data = useTypedSelector((state) => state.reserving);

  const [, handlePhysicianSelect] = useHandleSelect(setPhysician);
  const [, handlePatientSelect] = useHandleSelect(setPatient);
  const [, handleRoomSelect] = useHandleSelect(setRoom);
  const [, handleProcedureSelect] = useHandleSelect(setProcedure);
  const [comment, handleCommentChange] = useDebounce(
    data.comment,
    setComment,
    350
  );

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item md={6} xs={12}>
        <Autocomplete
          options={[
            { id: 1, title: 'Иван Иванов' },
            { id: 2, title: 'Пётр Петров' },
          ]}
          getOptionLabel={(option) => option.title}
          value={data.patient}
          onChange={handlePatientSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Пациент"
              variant="outlined"
              margin="normal"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          options={[
            { id: 1, title: 'Шоколадное обёртывание' },
            { id: 2, title: 'Лечение прыщей' },
          ]}
          getOptionLabel={(option) => option.title}
          value={data.procedure}
          onChange={handleProcedureSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Профиль консультации"
              variant="outlined"
              margin="normal"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          onChange={handlePhysicianSelect}
          value={data.physician}
          options={[
            { id: 1, title: 'Аркашин Доктор' },
            { id: 2, title: 'Витин Доктор' },
          ]}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Консультирующий врач"
              name="physician"
              variant="outlined"
              margin="normal"
              size="small"
            />
          )}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Autocomplete
          id="combo-box-demo"
          options={[
            { id: 1, title: '401' },
            { id: 2, title: '403' },
          ]}
          getOptionLabel={(option) => option.title}
          value={data.room}
          onChange={handleRoomSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Кабинет"
              variant="outlined"
              margin="normal"
              size="small"
            />
          )}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <TextField
          label="Комментарий"
          name="comment"
          variant="outlined"
          margin="normal"
          size="small"
          fullWidth
          multiline
          rows={6}
          placeholder="Введите комментарий..."
          value={comment}
          onChange={handleCommentChange}
        />
      </Grid>

      {/* <Grid container item spacing={1}>
          <Grid item md={3} xs={6}>
            <TextField
              name="dateFrom"
              variant="outlined"
              label="Дата от"
              type="date"
              margin="normal"
              fullWidth
              size="small"
              onChange={handleDateFromChange}
              value={data.dateFrom}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              variant="outlined"
              name="dateTo"
              label="Дата до"
              type="date"
              fullWidth
              size="small"
              margin="normal"
              onChange={handleDateToChange}
              value={data.dateTo}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid> */}
    </Grid>
  );
};
