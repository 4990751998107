import { DetailType } from 'enums/global';

export const makeEmptyBlock = (parentId: number, type: DetailType) => {
  switch (type) {
    case DetailType.title:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        text: '',
        type: DetailType.title,
      };

    case DetailType.subtitle:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        text: '',
        type: DetailType.subtitle,
      };

    case DetailType.paragraph:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        text: '',
        type: DetailType.paragraph,
      };

    case DetailType.header:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        places: 1,
        stars: 0,
        price: '',
        text: '',
        type: DetailType.header,
      };

    case DetailType.list:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        listItems: [],
        type: DetailType.list,
      };

    case DetailType.image:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        image: null,
        type: DetailType.image,
      };

    case DetailType.icons:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        iconsItems: [],
        type: DetailType.icons,
      };

    case DetailType.gallery:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        galleryItems: [],
        type: DetailType.gallery,
      };
    case DetailType.link:
      return {
        id: -1,
        infoDetailsScreenId: parentId,
        text: '',
        link: '',
        type: DetailType.link,
      };
  }
};
