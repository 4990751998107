import React from 'react';
import { ILink } from './interface/global';
/* import HomeIcon from '@material-ui/icons/Home'; */
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import { lightBlue, green } from '@material-ui/core/colors';
import { ResourceInstance } from '@devexpress/dx-react-scheduler';
import GroupIcon from '@material-ui/icons/Group';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonAddSharpIcon from '@material-ui/icons/PersonAddSharp';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaletteIcon from '@material-ui/icons/Palette';

export type Divider = string;

type AdminLinks = Array<ILink | Divider>;

export const ADMIN_LINKS: AdminLinks = [
  /* { link: '/admin/home', title: 'Главная', icon: <HomeIcon /> }, */
  { link: '/admin/reserving', title: 'Бронирование', icon: <EventNoteIcon /> },
  { link: '/admin/profile', title: 'Профиль', icon: <PersonIcon /> },
  { link: '/admin/guests', title: 'Гости', icon: <GroupIcon /> },
  { link: '/admin/wisiwyg', title: 'Баннеры', icon: <LineStyleIcon /> },
  {
    link: '/admin/notify',
    title: 'Общее уведомление',
    icon: <AddAlertIcon />,
  },
];

export const DRAWER_LINKS = [
  {
    link: '/admin/guests',
    title: 'Гости',
    Icon: SupervisedUserCircleIcon,
    menu: [
      { title: 'Список', link: '/admin/guests/list', icon: <ListAltIcon /> },
      {
        title: 'Создание',
        link: '/admin/guests/create',
        icon: <PersonAddSharpIcon />,
      },
      {
        title: 'Бронирование',
        link: '/admin/guests/reserving',
        icon: <EventNoteIcon />,
      },
    ],
  },
  {
    link: '/admin/terminal',
    title: 'Терминал',
    Icon: VideoLabelIcon,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/terminal/banners',
        icon: <ListAltIcon />,
      },
      {
        title: 'Добавить баннер',
        link: '/admin/terminal/createbanner',
        icon: <PostAddIcon />,
      },
      {
        title: 'Общее уведомление',
        link: '/admin/terminal/notify',
        icon: <AddAlertIcon />,
      },
      {
        title: 'Разделы',
        link: '/admin/terminal/sections',
        icon: <ViewDayIcon />,
      },
      {
        title: 'Детальные экраны',
        link: '/admin/terminal/endblocks',
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    link: '/admin/personal',
    title: 'Персонал',
    Icon: AssignmentIndIcon,
    menu: [
      {
        title: 'Список',
        link: '/admin/personal/list',
        icon: <ListAltIcon />,
      },
      {
        title: 'Общее уведомление',
        link: '/admin/personal/notify',
        icon: <AddAlertIcon />,
      },
    ],
  },
  {
    link: '/admin/info',
    title: 'Главная',
    Icon: AssignmentIndIcon,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/info/banners',
        icon: <ListAltIcon />,
      },
      {
        title: 'Разделы',
        link: '/admin/info/sections',
        icon: <ViewDayIcon />,
      },
      {
        title: 'Детальные экраны',
        link: '/admin/info/endblocks',
        icon: <DashboardIcon />,
      },
      {
        title: 'Цвета',
        link: '/admin/info/colors',
        icon: <PaletteIcon />,
      },
    ],
  },
];

export const CALENDAR_GROUPS: ResourceInstance[] = [
  { text: 'Врач', id: 1, color: lightBlue },
  { text: 'Пациент', id: 2, color: green },
];

export const mainColor = '#278CF5';
export const mainGradient =
  'linear-gradient(239.51deg, #6FB5FF 22.34%, #488DFF 77.81%)';
export const purpleGradient =
  'linear-gradient(232.84deg, #7880FF 22.99%, #5B64EC 80.51%)';
export const lightGradient =
  'linear-gradient(143.03deg, #B5CFF7 21.47%, #A0C6FF 95.48%)';
export const greenGradient =
  'linear-gradient(244.06deg, #2ADE9B 26.41%, #19D18D 75.21%)';
