import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ReservingAction } from 'interface/reserving';

type TChange = (event: React.ChangeEvent<HTMLInputElement>) => void;
type TCallback =
  | ((value: string) => (dispatch: Dispatch<ReservingAction>) => void)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any;

function useDebounceFunc<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useDebounce = (
  initialValue: unknown,
  callback: TCallback,
  timeout = 300,
  withoutDispatch?: boolean
): [typeof initialValue, TChange] => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<typeof initialValue>(initialValue);
  const debouncedValue = useDebounceFunc<typeof initialValue>(value, timeout);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    setValue(newValue);
  };

  useEffect(() => {
    if (withoutDispatch) {
      callback(String(value));
    } else {
      dispatch(callback(String(value)));
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return [value, handleChange];
};
