import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import { getCurrentNotification, postEditNotification } from 'api/notify';
import { doRequest } from 'hooks/doRequest';
import { Notification } from 'interface/notify';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IOSSwitch } from 'ui/IOSSwitch/IOSSwitch';
import { NotificationPreview } from './NotificationPreview';
import { useNotifyStyles } from './styles';
import DoneIcon from '@material-ui/icons/Done';
import { ColorPickers } from './ColorPickers';

export const Notify: React.FC = () => {
  const [currentData, setCurrentData] = useState<Notification>(
    initialNotificationState
  );

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const classes = useNotifyStyles({ isActive: currentData.isActive });

  const theme = useTheme();

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = async () => {
    const res = await doRequest<Notification>(getCurrentNotification);

    if (res.success) {
      setCurrentData(res.data);
    } else {
      toast.error('Ошибка получения уведомления');
    }

    setIsFetching(false);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentData((prevData) => ({
      ...prevData,
      currentNotification: {
        ...prevData.currentNotification,
        message: event.target.value,
      },
    }));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentData((prevData) => ({
      ...prevData,
      isActive: event.target.checked,
    }));
  };

  const handleFileChange = (path: string) => {
    setCurrentData((prevData) => ({
      ...prevData,
      currentNotification: {
        ...prevData.currentNotification,
        icon: path,
      },
    }));
  };

  const handleSaveNotification = async () => {
    setIsFetching(true);
    const res = await doRequest(postEditNotification, {
      notification: { ...currentData.currentNotification },
      isActive: currentData.isActive,
    });
    if (res.success) {
      toast.success('Уведомление сохранено');
    } else {
      toast.error('Ошибка сохранения уведомления');
    }
    setIsFetching(false);
  };

  const handleBgColorChange = (fieldName: string, newColor: string) => {
    setCurrentData((prevData) => ({
      ...prevData,
      currentNotification: {
        ...prevData.currentNotification,
        backgroundGradient: {
          ...prevData.currentNotification.backgroundGradient,
          [fieldName]: newColor,
        },
      },
    }));
  };

  const handleTextColorChange = (newColor: string) => {
    setCurrentData((prevData) => ({
      ...prevData,
      currentNotification: {
        ...prevData.currentNotification,
        textColor: newColor,
      },
    }));
  };

  return (
    <Fade in timeout={500}>
      <div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mb={4}
        >
          <Typography variant="h2">Общее уведомление</Typography>
          <div>
            <span className={classes.isActive}>
              {currentData.isActive ? 'Активно' : 'Неактивно'}
            </span>
            <IOSSwitch
              onChange={handleSwitchChange}
              checked={currentData.isActive || false}
            />
          </div>
        </Box>

        <Box display="flex" flexDirection="row">
          <NotificationPreview
            data={currentData}
            editMode
            handleMessageChange={handleMessageChange}
            onFileChange={handleFileChange}
          />
          <IconButton
            style={{
              width: 40,
              height: 40,
              borderRadius: '100%',
              background: theme.palette.primary.main,
              marginLeft: 24,
            }}
            onClick={handleSaveNotification}
            disabled={isFetching}
          >
            {isFetching ? (
              <CircularProgress size={15} style={{ color: 'white' }} />
            ) : (
              <DoneIcon htmlColor="#fff" />
            )}
          </IconButton>
        </Box>

        <ColorPickers
          textColor={currentData.currentNotification.textColor}
          backgroundColor={currentData.currentNotification.backgroundGradient}
          onBackggroundColorChange={handleBgColorChange}
          onTextColorChange={handleTextColorChange}
          possibleBackgrounds={currentData.possibleBackgrounds}
        />
      </div>
    </Fade>
  );
};

export const initialNotificationState: Notification = {
  currentNotification: {
    message: 'Cообщение для пользователя',
    backgroundGradient: {
      startColor: '#424344',
      endColor: '#363637',
    },
    textColor: '#FFFFFF',
    icon: '',
  },
  possibleBackgrounds: [
    {
      startColor: '#28CD8F',
      endColor: '#22D291',
    },
    {
      startColor: '#424344',
      endColor: '#363637',
    },
  ],
  isActive: false,
};
