import { instance } from './instance';

const { get, post } = instance;

export const getAllSections = () => get('/info/admin/sections');
export const getCurrentSection = (sectionId: number) =>
  get(`/info/admin/sections/${sectionId}`);
export const getCurrentSectionBlocks = (sectionId: number) =>
  get(`/info/admin/sections/${sectionId}/blocks`);

export const getAllDetails = () => get(`/info/admin/details`);

export const postEditBlock = (data: any) =>
  post('/info/admin/sections/blocks/update', data);

export const postCreateBlock = (data: any) =>
  post('/info/admin/sections/blocks/add', data);

export const postEditDetailsScreen = (data: any) =>
  post('/info/admin/details', data);

export const makeMain = ({
  sectionId,
  ids,
}: {
  sectionId: number;
  ids: number[];
}) =>
  post(`/info/admin/sections/${sectionId}/children`, {
    infoSectionId: sectionId,
    infoBlockIds: ids,
  });
/* export const getAllSections = () => get('/info/admin/sections');
export const getAllSections = () => get('/info/admin/sections');
export const getAllSections = () => get('/info/admin/sections'); */
