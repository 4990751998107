import {
  AuthActions,
  AuthState,
  EmployeeState,
  LoginActions,
} from 'interface/login';

const initialState: EmployeeState = {
  employeeUuid: '',
  id: 0,
  id1c: 0,
  name: '',
  surname: '',
  patronymic: '',
  login: '',
  password: '',
  days: [],
  isAuth: AuthState.WAIT_AUTH,
  isLoading: false,
};

export const loginReducer = (
  state: EmployeeState = initialState,
  action: AuthActions
): EmployeeState => {
  switch (action.type) {
    case LoginActions.ME_SUCCESS:
      return {
        ...action.payload,
        isAuth: AuthState.HAS_AUTH,
        isLoading: false,
      };
    case LoginActions.ME_FAILED:
      return {
        ...initialState,
        isAuth: AuthState.NOT_AUTH,
        isLoading: false,
      };
    case LoginActions.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LoginActions.END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};
