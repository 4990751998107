import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMenuHandle = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const windowProps = {
    anchorEl,
    open: !!anchorEl,
    onClose: handleClose,
    id: 'simple-menu',
  };

  return {
    anchorEl,
    handleOpen,
    handleClose,
    open: !!anchorEl,
    windowProps,
  };
};
