import { Container, TextField } from '@material-ui/core';
import { SectionBg } from 'components/SectionBg/SectionBg';
import { useDebounce } from 'hooks/useDebounce';
import { BlocksData } from 'interface/wisiwyg';
import React from 'react';
import { RenderWisiwygBlocks } from './RenderWisiwygBlocks';
import { useStyles } from './styles';

interface Props {
  data: BlocksData;
  setData: React.Dispatch<React.SetStateAction<BlocksData>>;
  handleMainBgChange: (path: string) => void;
}

export const FullInfo: React.FC<Props> = ({
  data,
  handleMainBgChange,
  setData,
}: Props) => {
  const classes = useStyles();
  const [titleValue, handleTitleChange] = useDebounce(
    data.title,
    (newValue: string) => setData({ ...data, title: newValue }),
    300,
    true
  );
  return (
    <>
      <SectionBg
        image={data.mainImage}
        time={false}
        backButton={''}
        editMode
        handleImageChange={handleMainBgChange}
      />
      <Container>
        <TextField
          onChange={handleTitleChange}
          placeholder="Заголовок"
          multiline
          fullWidth
          className={classes.title}
          value={titleValue}
        />
        <RenderWisiwygBlocks data={data} setData={setData} editMode />
      </Container>
    </>
  );
};
