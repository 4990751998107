import Guests from 'components/Guests/Guests';
import Reserving from 'components/Reserving/Reserving';
import React from 'react';
import { Redirect, Route } from 'react-router';

export const GuestsPage: React.FC = () => {
  return (
    <>
      <Route path={'/admin/guests/reserving'}>
        <Reserving />
      </Route>
      <Route path={'/admin/guests/list'}>
        <Guests />
      </Route>
      <Route>
        <Redirect to="/admin/guests/list" />
      </Route>
    </>
  );
};
