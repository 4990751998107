import { NotificationPlank } from 'common/NotificationPlank';
import { Notification } from 'interface/notify';
import React from 'react';

interface Props {
  data: Notification;
  editMode?: boolean;
  handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (path: string) => void;
}

export const NotificationPreview: React.FC<Props> = ({
  data,
  editMode,
  onFileChange,
  handleMessageChange,
}: Props) => {
  return (
    <section>
      <NotificationPlank
        {...data.currentNotification}
        onFileChange={onFileChange}
        editMode={editMode}
        handleMessageChange={handleMessageChange}
      />
    </section>
  );
};
