import React from 'react';

export const DotsIcon: React.FC = () => {
  return (
    <svg
      width="45"
      height="6"
      viewBox="0 0 45 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" fill="#208DFF" />
      <circle cx="16" cy="3" r="3" fill="#C7DBF3" />
      <circle cx="29" cy="3" r="3" fill="#C7DBF3" />
      <circle cx="42" cy="3" r="3" fill="#C7DBF3" />
    </svg>
  );
};
