import { getAllDetails } from 'api/sections';
import { doRequest } from 'hooks/doRequest';
import React, { useEffect, useState, FC } from 'react';
import BrandSelect from 'ui/BrandSelect/BrandSelect';

const allDetailsRequest = async (cb: (data: any) => void) => {
  const result = await doRequest<any | null>(getAllDetails);
  cb(result.data?.data);
};

type Props = {
  value: number;
  setValue: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
    child?: React.ReactNode
  ) => void;
};

const SelectDetailsScreen: FC<Props> = ({ value, setValue }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    allDetailsRequest(setOptions);
  }, []);

  return (
    <BrandSelect
      value={value}
      onChange={setValue}
      selectId={'end-screen-select'}
      options={options}
    />
  );
};

export default SelectDetailsScreen;
