import React from 'react';
import TitleIcon from '@material-ui/icons/Title';
import ImageIcon from '@material-ui/icons/Image';
import SubjectIcon from '@material-ui/icons/Subject';
import ListIcon from '@material-ui/icons/List';
import { Box, Button, Grid } from '@material-ui/core';
import { BLOCK_TYPE } from 'interface/wisiwyg';
import { useStyles } from './styles';

const buttons: BlockButton[] = [
  { title: 'Заголовок', Icon: TitleIcon, type: BLOCK_TYPE.TITLE },
  { title: 'Текст', Icon: SubjectIcon, type: BLOCK_TYPE.PARAGRAPH },
  { title: 'Изображение', Icon: ImageIcon, type: BLOCK_TYPE.IMAGE },
  { title: 'Список', Icon: ListIcon, type: BLOCK_TYPE.LIST },
];

interface BlockButton {
  title: string;
  Icon: React.FC;
  type: BLOCK_TYPE;
}

interface AddBlockButtonProps extends BlockButton {
  handleAddBlock: (type: BLOCK_TYPE) => void;
}

export const AddBlockBar: React.FC<
  Pick<AddBlockButtonProps, 'handleAddBlock'>
> = ({ handleAddBlock }: Pick<AddBlockButtonProps, 'handleAddBlock'>) => {
  const classes = useStyles();

  return (
    <Box
      clone
      mt={{
        xs: 2,
        md: 4,
      }}
      mb={{
        xs: 4,
        md: 8,
      }}
    >
      <div className={classes.addBlockBar}>
        <Grid container spacing={2}>
          {buttons.map((button) => (
            <Grid key={button.title} item xs={12} sm={6} md={4} lg={3}>
              <AddBlockButton {...button} handleAddBlock={handleAddBlock} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

const AddBlockButton = ({
  title,
  Icon,
  type,
  handleAddBlock,
}: AddBlockButtonProps) => {
  const classes = useStyles();
  return (
    <Box
      clone
      p={{
        xs: 1,
        sm: 2,
      }}
    >
      <Button
        component="div"
        startIcon={<Icon />}
        className={classes.addBlockButton}
        onClick={() => handleAddBlock(type)}
      >
        <div className={classes.addBlockButtonTitle}>{title}</div>
      </Button>
    </Box>
  );
};
