import { Dispatch } from 'redux';
import { IOption } from 'interface/global';
import { ReservingAction, ReservingActions } from 'interface/reserving';

export const setDateFrom = (value: Date | string) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_DATE_FROM, payload: value });
  };
};

export const setDateTo = (value: Date | string) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_DATE_TO, payload: value });
  };
};

export const setPatient = (value: IOption | null) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_PAT, payload: value });
  };
};

export const setProcedure = (value: IOption | null) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_PROCEDURE, payload: value });
  };
};

export const setPhysician = (value: IOption | null) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_PHYS, payload: value });
  };
};

export const setRoom = (value: IOption | null) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_ROOM, payload: value });
  };
};

export const setComment = (value: string) => {
  return (dispatch: Dispatch<ReservingAction>): void => {
    dispatch({ type: ReservingActions.SET_COMMENT, payload: value });
  };
};
