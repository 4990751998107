import { useState, useEffect } from 'react';
import { doRequest } from './doRequest';
import { AxiosResponse } from 'axios';

type useGetDataArgs = {
  request: (...args: any[]) => Promise<AxiosResponse<any>>;
  dependency?: any[];
  requestBody?: any;
};

const useGetData = <T extends any>({
  request,
  dependency = [],
  requestBody,
}: useGetDataArgs) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const getData = async () => {
    try {
      setLoading(true);
      const res = await doRequest<any>(request, requestBody);
      if (res?.data?.data) {
        setData(res.data?.data);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, dependency);

  return {
    data: data as T,
    loading,
    error,
    getData,
  };
};

export default useGetData;
