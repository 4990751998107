import { makeStyles } from '@material-ui/core';

export const useBlocksStyles = makeStyles((theme) => ({
  centerInput: {
    '& input, & textarea': {
      textAlign: 'center',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  priceInput: {
    '& input': {
      background: '#32BACB',
      borderRadius: 24,
      textAlign: 'center',
      padding: 8,
      color: 'white',
      fontSize: 18,
      fontWeight: 500,
    },
  },
  headerInput: {
    '& input, & textarea': {
      marginRight: 16,
      fontWeight: 700,
      fontSize: 24,
      color: '#262626',
    },
  },
  linkInput: {
    '& input, & textarea': {
      color: theme.palette.primary.main,
    },
  },
}));
