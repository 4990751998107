import React, { FC, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useEmptyBlockStyles } from './styles';
import { useTheme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import TextClamp from 'react-string-clamp';
import IconButton from '@material-ui/core/IconButton';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

type EmptyBlockProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  imgSrc?: string;
  isMain?: boolean;
  describe?: string;
  disabled?: boolean;
  disabledReason?: string;
  onSidebuttonClick?: () => void;
};

const EmptyBlock: FC<EmptyBlockProps> = ({
  onClick,
  imgSrc,
  isMain,
  describe,
  onSidebuttonClick,
  disabled,
  disabledReason,
}) => {
  const classes = useEmptyBlockStyles({ inside: !!imgSrc, disabled });
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (disabled) return () => undefined;
    return onClick;
  };

  console.log('isMain', isMain);

  return (
    <Tooltip
      placement="top"
      title={disabledReason || ''}
      disableHoverListener={!disabled}
    >
      <div onClick={handleClick()} className={classes.root}>
        {imgSrc ? (
          <img src={imgSrc as string} alt="photo" />
        ) : (
          <AddIcon style={{ color: theme.palette.primary.main }} />
        )}
        <TextClamp lines={3} text={describe || 'Создать'} />
        {imgSrc && (
          <IconButton
            className={classes.iconButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSidebuttonClick && onSidebuttonClick();
            }}
          >
            {isMain ? (
              <FlagIcon style={{ color: 'rgb(220, 0, 78)' }} />
            ) : (
              <FlagOutlinedIcon />
            )}
          </IconButton>
        )}
      </div>
    </Tooltip>
  );
};

export default EmptyBlock;
